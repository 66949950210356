@import "./style.scss";

.item{
    background-color: $NewBlue;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 0 30px #7c7c7cad;
    transition: .3s;
}
.item:hover{
    opacity: .9;
}

.content-top {
    position: relative;

    .content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: rgba(0,0,0,.7);
    }
    
    img.main-img {
        width: 100%;
        height: 250px;
        object-fit: cover;
    }

    a.link-wrap {
        display: flex;
        width: 100%;
    }
    h3 {
        font-size: 80px;
    }
}

.content-bottom {
    padding: 20px 30px 40px 30px;
    position: relative;

    a.readmore {
        position: absolute;
        right: 30px;
        color: #fff;
    }
}
