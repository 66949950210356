@import "./style.scss";
.seoPlan-container{
    padding:100px 50px;
    color:#040040;
    .seoPlan-title{
        text-align: left;
        margin-bottom: 30px;
    }
  
    @media all and (max-width: 480px) {
       
        .seoPlan-title{ 
            text-align: center;
        }
        .tooltip{
            display: none;
            position: relative;
            margin: 2px 0 0 10px;
        }
    }
    .seoPlan-main{
        text-align: center;
        margin:27px -55px 20px -55px;
        .MuiTable-root tr th:nth-child(odd) {
            background-color: #4ed199;
        }
        .MuiTable-root tr td:nth-child(odd) {
            background-color:#4ed199
        }
        // .MuiTable-root tr th:nth-child(even) {
        //     background-color:#040040
        // }
        // .MuiTable-root tr td:nth-child(even) {
        //     background-color:#040040
        // }
       
    }
    
}
.seo-plan {
    .plan-table{
        margin-top: 20px;
        .bs-value{
           
            li{
                font-size: 19px;
                font-weight: 600;
            }
        }
        .plan-table-col{
           
            li{
                min-height: 60px;
                padding: 0 7px;
                vertical-align: middle;
                border-bottom: 1px solid $lightGray;
                background-color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                line-height: 1;
                
                h4{
                    font-size: 28px;

                }
                .btn{
                    padding: 10px;
                    width: 100%;
                }
            }
            li:first-of-type{
                border: none;
                background: transparent;
            }
            // li:nth-of-type(2){
                 
            // }
        }
        .service{
            li:nth-of-type(2){
                border: none;
                background: transparent!important;
            }
            li{
                justify-content: flex-end;
                padding: 10px 20px;
            }
        }
        .task{
            margin:2px 35px 0 0;
            color:#000;
        }
        .tooltip{
            display: none;
            position: relative;
            margin: 2px 0 0 10px;
            cursor: pointer;
            svg{
                color: gray;
                transition: .3s;
            }
            .tip{
                display: none;
                position: absolute;
                top: -20px;
                left: 20px;
                background: #333;
                color: #fff;
                padding: 8px;
                font-size: 10px;
                line-height: normal;
                text-align: center;
                border-radius: 4px;
                width: 160px;
                transition: .3s;
            }
            
        }
        @media all and (max-width: 820px){
            .tooltip{
                position: absolute;
                margin: 2px 0 0 10px;
                cursor: pointer;
                right: 200px;
            }
          }
        @media all and (max-width: 480px) {
            .tooltip{
                position: absolute;
                margin: 2px 0 0 10px;
                cursor: pointer;
                right: 38px;
            }
            .task{
                color:#040040;
            }
        }
        .tooltip:hover{
            svg{
                color: #333;
            }
            .tip{
                display: block;
            }
        }
        .plan{
            li{
                svg{
                    font-size: 24px;
                }
            }
        }
        .plan1{
            li:nth-of-type(2){
                background: $LogoBlue !important;
                color: #fff;
            }
            li{
                color:#040040;
            }
        }
        .plan2{
            li:first-of-type{
                background-color: $Green;
                color: #fff;
                text-transform: uppercase;
                letter-spacing: 2px;
                border-radius: 20px 20px 0 0;
            }
            li:nth-of-type(2){
                background: $LogoPurple !important;
                color: #fff;
            }
            li{
                background:#e7ebed;
                color:#040040;
            }
        }
        .plan3{
            li:nth-of-type(2){
                background: $Orange!important;
                color: #fff;
            }
            li{
                color:#040040;
            }
        }
        .yes{
            color: $Green;
        }
        .no{
            color: #d34343;
        }
    }
}