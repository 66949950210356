@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  line-height: 1.8;
  overflow-y: scroll;
  min-height: 100vh;
}

ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a{
  text-decoration: none;
  color: inherit;
}

h1, h2, h3, h4, h5, h6{
  margin: 0;
  line-height: 1.2;
}