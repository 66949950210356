.contact_form_title {
    box-shadow: 0 0 20px rgb(0 0 0 / 10%);
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    padding: 0 !important;

    .boxtringle {
      position: relative;
      padding: 30px 50px 50px 50px !important;
      border-top: 2px solid #040040;

      &::after {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        top: 0;
        right: 0;
        border-style: solid;
        border-width: 0 50px 50px 0;
        border-color: transparent #040040 transparent transparent;
        transform: rotate(0deg);
      }

      &::before {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        top: 0;
        left: 0;
        border-style: solid;
        border-width: 50px 50px 0 0;
        border-color: #040040 transparent transparent transparent;
        transform: rotate(0deg);
      }

      h3 {
        text-align: center;
        margin-top: 20px;
      }

      @media only screen and (max-width: 1200px) {
        h3 {
          font-size: 30px;
        }
      }

      @media only screen and (max-width: 600px) {
        h3 {
          font-size: 24px;
        }
      }

      @media only screen and (max-width: 376px) {
        h3 {
          font-size: 22px;
        }
      }

      h5 {
        margin: 10px 0 40px 0;
        font-size: 22px;
        color: #040040;
      }

      @media only screen and (max-width: 376px) {
        h5 {
          font-size: 18px;
        }
      }

      form {
        .forminsideinput {
          margin: 15px 0 0;
          width: 100%;

          .input_group {

            .MuiInputBase-root {
              width: 100%;
              padding-right: 15px;
            }

            // input,
            // fieldset {
            //   border-color: #040040;
            // }

            input:focus {
              outline: 0;
            }
          }

          .padding_leftform {
            padding-right: 15px !important;
          }

          .btn{
            margin: 0 0 0 auto;
          }


          @media only screen and (max-width: 600px) {
            .padding_leftform {
              padding-right: 0 !important;
            }
          }
        }
      }
    }

    @media only screen and (max-width: 600px) {
      .boxtringle {
        padding: 30px 30px 50px 30px !important;
      }
    }
  }