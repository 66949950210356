@import "./style.scss";
.ecommerce{
    .strategy{
        h4{
            font-size: 16px;
            text-align: center;
            color: $white;
        }
    }
    h2,h3{
        text-align: center;
        margin-bottom:30px;
    }
    .ecommerce_title{
        margin:10px;
    }
    .features_box {
    
        .features_box_inner{
            background: $NewDarkBlue;
            transition: .3s;
            border-radius: 25px;
            padding: 20px 40px 30px 40px;
            margin: 15px 15px 15px 15px;
            box-shadow: 0px 0px 30px 0px rgba(11, 11.999999999999998, 37, 0.1);
            img{
                text-align: center;
                width: 100%;
                max-width: 100%;
                height: 120px;
                object-fit: contain;
                padding: 20px;
            }
            p{
                font-size: 20px;
                line-height: 32px;
            }
        }
        .features_box_inner:hover{
            background: $NewBlue;
        }
    }
    .ecommerce_platform {
        h2{
            color: $NewDarkBlue;
            max-width: 800px;
            margin: 0 auto 30px auto;
        }
        h3{
            font-size: 20px;
            color: $Green;
            font-weight: normal;
            letter-spacing: 2px;
        }
        .platform_box_inner{
            padding: 40px;
            height: 100%;
        }
    }
}