@import "./style.scss";
.banner{
    .banner-overlay {
        .banner-con {
            margin:0;
        }
    }
    h1{
       
        text-align: left;
    }
} 
.support-plan{
    .website_features_wp{
        .website_features{
            .features_box{
                .features_box_inner{
                    .website_features_icon{
                        background: #fff;
                        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                    }
                    .price{
                        color:$Green;
                        font-size:45px;
                        font-weight:600 ;
                    }
                    p{
                        color:#888888;
                        font-size: small;
                    }
                    ul{
                        li{
                            border-top: 1px dashed #E5E4E2;
                            border-bottom: 1px dashed #E5E4E2;
                            padding: 25px;
                        }
                    }
                    p{
                        a{
                            margin-top: 30px;
                            border-radius: 30px;
                            background: #fff;
                            border:3px solid $Green;
                        }
                        a:hover{
                            margin-top: 30px;
                            border-radius: 30px;
                            background:$NewDarkBlue;
                            color:$Green;
                            border:3px solid $NewDarkBlue;
                        }
                    }
                } 
            } 
        } 
    } 
}
.inclusions{
    h2{
        margin-bottom: 30px;
    }
    svg{
        color: $Green;
        font-size: 60px;
    }
    h3{
        margin: 10px 0;
        font-size: 24px;
    }
    .inclusions-item{
        padding: 40px 30px 0 30px;
    }
}

.pricing-plan {
    .plan-table{
        .plan-table-col{
            li{
                min-height: 54px;
                padding: 0 10px;
                vertical-align: middle;
                border: 1px solid $lightGray;
                background-color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                line-height: 1;
                h4{
                    font-size: 25px;

                }
                .btn{
                    padding: 10px;
                    width: 100%;
                }
            }
            li:first-of-type{
                border: none;
                background: transparent;
            }
            li:nth-of-type(2n){
                background-color: $lightGray;
            }
        }
        .service{
            li:nth-of-type(2), li:last-of-type{
                border: none;
                background: transparent!important;
            }
            li{
                justify-content: flex-end;
                padding: 10px 20px;
            }
        }
        .tooltip{
            position: relative;
            margin: 2px 0 0 10px;
            cursor: pointer;
            svg{
                color: gray;
                transition: .3s;
            }
            .tip{
                display: none;
                position: absolute;
                top: -20px;
                left: 20px;
                background: #333;
                color: #fff;
                padding: 8px;
                font-size: 10px;
                line-height: normal;
                text-align: center;
                border-radius: 4px;
                width: 160px;
                transition: .3s;
            }
        }
        @media all and (max-width: 480px) {
            .tooltip{
                position: absolute;
                margin: 2px 0 0 10px;
                cursor: pointer;
                right: 38px;
            }
        }
        .tooltip:hover{
            svg{
                color: #333;
            }
            .tip{
                display: block;
            }
        }
        .plan{
            li{
                svg{
                    font-size: 24px;
                }
            }
        }
        .plan1{
            li:nth-of-type(2){
                background: $LogoBlue!important;
                color: #fff;
            }
        }
        .plan2{
            li:first-of-type{
                background-color: $Green;
                color: #fff;
                text-transform: uppercase;
                letter-spacing: 2px;
                border-radius: 20px 20px 0 0;
            }
            li:nth-of-type(2){
                background: $LogoPurple!important;
                color: #fff;
            }
        }
        .plan3{
            li:nth-of-type(2){
                background: $Orange!important;
                color: #fff;
            }
        }
        .yes{
            color: $Green;
        }
        .no{
            color: #d34343;
        }
    }
    
}

.webcare .default-sec-overlay .default-sec-content .default-sec-wrap{
    max-width: 1040px;
    padding: 0 50px;
    margin: 0 auto;
}
.brief .default-sec-overlay .default-sec-content .default-sec-wrap{
    max-width: 1040px;
    padding: 0 50px;
    margin: 0 auto;
}