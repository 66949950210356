@import "./style.scss";

.seo.page-content{
  h2{
    margin-bottom: 20px;
  }
  @media all and (max-width: 480px) {
    .seo.page-content{
      h2{
        text-align: center;
      }
      p{
        text-align: justify;
      }
    }
  }
  .text-grid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: fit-content;
  }

  .text-btn {
    width: 200px;
  }

  @media only screen and (max-width: 900px) {
    .inner-text-point-left {
      padding-right: 20px !important;
      padding-left: 30px !important;
    }
  }

  .inner-text-point-right {
    padding: 50px 40px 50px 0px;
  }

  @media only screen and (max-width: 900px) {
    .inner-text-point-right {
      padding-left: 20px !important;
      padding-right: 30px !important;
    }
  }
}

.text {
  padding: 15px 0;
}

.our_services_wp {
  margin: 0 auto;
  width: 100%;
}

.text-point.support_wp {
  padding-top: 0;
  padding-bottom: 50px;
  width: 100%;
  margin: 0 auto;

  .sec-title {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
  }

  .responsive_prosup {
    display: flex;

    @media only screen and (max-width: 600px) {
      .One_off_project {
        padding-top: 0;
        max-width: 100%;
        flex-basis: 100%;
      }
    }

    @media only screen and (max-width: 600px) {
      .inner-text-point-right {
        padding-left: 30px;
        padding-right: 30px;
        max-width: 100%;
        flex-basis: 100%;
      }
    }
  }

  .responsive_prosup_bottom {
    display: flex;

    @media only screen and (max-width: 600px) {
      .right_img_provide {
        max-width: 100%;
        flex-basis: 100%;
      }
    }

    @media only screen and (max-width: 600px) {
      .seo_consultancy {
        padding-top: 0;
        max-width: 100%;
        flex-basis: 100%;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .responsive_prosup_bottom {
      flex-direction: column;
    }
  }

  @media only screen and (max-width: 600px) {
    .responsive_prosup {
      flex-direction: column-reverse;
    }
  }

  @media only screen and (max-width: 992px) {
    .sec-title {
      font-size: 24px;
    }
  }

  @media only screen and (max-width: 900px) {
    .sec-title {
      margin-top: 0;
    }
  }

  @media only screen and (max-width: 600px) {
    .sec-title {
      margin-top: 0;
      margin-left: 30px;
      margin-right: 30px;
      font-size: 22px;
      line-height: normal;
    }
  }

  .One_off_project {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    text-align: right;
    justify-content: center;

    @media only screen and (max-width: 600px) {
      h3 {
        width: 100%;
        font-size: 24px;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .One_off_project {
      text-align: left;
      padding-bottom: 20px;
    }
  }

  .right_img_provide {
    padding: 50px 0 50px 70px !important;
    text-align: right;
  }

  @media only screen and (max-width: 600px) {
    .right_img_provide {
      text-align: left;
      padding-left: 30px !important;
      padding-right: 30px !important;
      padding-top: 0 !important;
    }
  }

  .seo_consultancy {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    padding-left: 80px !important;
  }

  @media only screen and (max-width: 600px) {
    .seo_consultancy {
      padding-left: 30px !important;
      padding-right: 30px !important;
      padding-bottom: 0 !important;
      padding-top: 0 !important;

      @media only screen and (max-width: 600px) {
        h3 {
          width: 100%;
          font-size: 24px;
        }
      }
    }
  }

  .maximg_seo {
    max-width: 300px;
  }

  @media only screen and (max-width: 900px) {
    .maximg_seo {
      max-width: 200px;
    }
  }

  .btn_support {
    width: 260px;
  }
}

.common_wp {
  padding-top: 0;
  padding-bottom: 50px;

  h3 {
    margin-bottom: 20px;
  }

  .text_common {
    margin-bottom: 15px;
  }
}

.grow_businesses {
  margin: 0 auto;
  width: 100%;

  .grow_title {
    padding-top: 0 !important;
    text-align: center;
    padding-bottom: 20px !important;

    @media only screen and (max-width: 600px) {
      h3 {
        font-size: 26px;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .grow_title {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .grow_list {
    padding: 20px;

    .grow_inner {
      display: flex;
      align-items: center;
      background-color: #F7F7F7;
      border: 1px solid #040040;
      border-radius: 5px;
      height: 100%;

      .grow_icon {
        margin-right: 10px;

        img {
          width: 50px;
          margin-top: 8px;
          padding-left: 10px;
        }
      }

      .grow_name {
        background-color: #040040;
        height: auto;
        color: #ffffff;
        width: 100%;
        align-items: flex-start;
        padding: 15px;
        height: 100%;

        h4 {
          font-size: 20px;
        }

        p {
          color: #ffffff;
          font-size: 14px;
          line-height: normal;
        }
      }

      @media only screen and (max-width: 600px) {}
    }
  }

  @media only screen and (max-width: 900px) {
    .grow_list {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

@media only screen and (max-width: 900px) {
  .grow_businesses {
    padding: 0 30px;
  }
}

.text-point.revewe_slider {
  width: 100%;

  .swiper {
    padding: 50px 20px;
    background-color: #F7F7F7;
    border-radius: 10px;

    .slide-item {
      a {
        .client {
          font-size: 30px;
          font-weight: 700;
          margin-bottom: 10px;
        }
      }

      @media only screen and (max-width: 992px) {
        p.testimonial {
          font-size: 16px;
          line-height: normal;
        }
      }

      .client {
        font-size: 18px;
      }


    }
  }

  @media only screen and (max-width: 992px) {
    .swiper {
      padding: 30px 20px;
    }
  }
}

@media only screen and (max-width: 900px) {
  .text-point.revewe_slider {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.text-point.future_businesses {
  margin: 0 auto;
  width: 100%;
  margin-top: 50px;

  .future_inside {
    padding: 0 20px;
    width: 100%;

    .future_leftright {
      display: flex;
      padding: 100px 0;
      align-items: center;
      justify-content: center;
      background-image: linear-gradient(#040040, #7035EC);
      width: 100%;
      border-radius: 10px;

      .future_left_title {
        width: 60%;
        text-align: center;
        padding-left: 50px;

        h4 {
          color: #ffffff;
          font-size: 26px;
          font-weight: 600;
          line-height: 40px;
        }
      }

      .future_right_btn {
        width: 40%;
        text-align: center;

        a.btn {
          background-color: #ffffff;
          color: #040040;
        }
      }
    }
  }
}

.text-point.accordion_wp {
  margin: 0 auto;

  .accordion_title {
    text-align: center;

    @media only screen and (max-width: 600px) {
      h3 {
        font-size: 26px;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .accordion_title {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .accordion_inside {
    padding: 0 20px;
    margin: 0 -15px;

    .testimonial-wrapper {
      padding: 0 15px;
      -webkit-flex-basis: 50%;
      -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
      -webkit-box-flex: 0;
      -webkit-flex-grow: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      max-width: 50%;
    }

    @media only screen and (max-width: 992px) {
      .testimonial-wrapper {
        max-width: 100%;
        flex-basis: 100%;
      }
    }

    .MuiPaper-elevation {
      margin-bottom: 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      border-radius: 10px;

      .MuiButtonBase-root {
        background-color: #040040;
        color: #ffffff;
        border-radius: 10px;
        padding: 0 15px 0 28px;

        .MuiAccordionSummary-expandIconWrapper {
          width: 16px;
          height: 16px;
          background-image: url(../icons/plus.png);
          background-repeat: no-repeat;
          background-size: 16px 16px;
          transform: unset;
          position: absolute;
          left: 8px;

          svg {
            display: none;
          }
        }

        .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
          background-image: url(../icons/minus.png);
          background-repeat: no-repeat;
        }

        .MuiTypography-root {
          color: #ffffff;
        }
      }

      .MuiButtonBase-root.Mui-expanded {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

    }
  }

  @media only screen and (max-width: 900px) {
    .accordion_inside {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

@media only screen and (max-width: 900px) {
  .text-point.accordion_wp {
    padding: 0 30px;
  }
}

.text-point.seo_blog_wp {
  width: 100%;

  .inside_seo_blog {
    padding: 0 20px;

    .blog-item {
      h1 {
        font-size: 24px !important;
        font-weight: 700;
        margin: 8px 0;
      }

      p {
        margin: 0;
      }

      p {
        span.readmore {
          float: right;
        }
      }
    }
  }

  @media only screen and (max-width: 900px) {
    .inside_seo_blog {
      padding: 0;
    }
  }
}

@media only screen and (max-width: 900px) {
  .text-point.seo_blog_wp {
    padding: 0 30px;
  }
}

.text-point.contact_form_wp {
  width: 100%;
  justify-content: center;



  .contact_form_title {
    box-shadow: 0 0 20px rgb(0 0 0 / 10%);
    padding: 0 !important;

    .boxtringle {
      position: relative;
      padding: 30px 50px 50px 50px !important;
      border-radius: 25px;

      // &::after {
      //   content: '';
      //   width: 0;
      //   height: 0;
      //   position: absolute;
      //   top: 0;
      //   right: 0;
      //   border-style: solid;
      //   border-width: 0 50px 50px 0;
      //   border-color: transparent #040040 transparent transparent;
      //   transform: rotate(0deg);
      // }

      // &::before {
      //   content: '';
      //   width: 0;
      //   height: 0;
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   border-style: solid;
      //   border-width: 50px 50px 0 0;
      //   border-color: #040040 transparent transparent transparent;
      //   transform: rotate(0deg);
      // }

      h3 {
        text-align: center;
      }

      @media only screen and (max-width: 1200px) {
        h3 {
          font-size: 30px;
        }
      }

      @media only screen and (max-width: 600px) {
        h3 {
          font-size: 24px;
        }
      }

      @media only screen and (max-width: 376px) {
        h3 {
          font-size: 22px;
        }
      }

      h5 {
        font-size: 22px;
        color: #040040;
      }

      @media only screen and (max-width: 376px) {
        h5 {
          font-size: 18px;
        }
      }

      form {
        .forminsideinput {
          margin: 15px 0 0;
          width: 100%;

          .input_group {
            // margin: 0 0 15px;
            // padding: 0 !important;

            .MuiInputBase-root {
              width: 100%;
              padding-right: 15px;
            }

            input,
            fieldset {
              border-color: #040040;
            }

            input:focus {
              outline: 0;
            }
          }

          .padding_leftform {
            padding-right: 15px !important;
          }

          @media only screen and (max-width: 600px) {
            .padding_leftform {
              padding-right: 0 !important;
            }
          }
        }
      }
    }

    @media only screen and (max-width: 600px) {
      .boxtringle {
        padding: 30px 30px 50px 30px !important;
      }
    }
  }

  @media only screen and (max-width: 900px) {
    .contact_form_title {
      max-width: 100%;
      flex-basis: 100%;
    }
  }
}

@media only screen and (max-width: 900px) {
  .text-point.contact_form_wp {
    padding: 0 30px;
  }
}