@import "./style.scss";

.grid-bot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    border-top: 1px solid #efefef;
}
span.author {
    display: flex;
    align-items: center;
}

span.author img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 40px;
    object-fit: cover;
}

.blog-item {
    h1 {
        font-size: 20px !important;
        font-weight: 500;
        line-height: 1.2;
        padding: 10px 0;
    }
    position: relative;
    ul.categories {
        position: absolute;
        top: 10px;
        right: 0;
        display: none;
    }
    img {
        height: 250px;
        width: 100%;
        object-fit: cover;
    }
}

